/* For webkit browsers like Chrome, Safari, and Edge */
input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    background: #bdbdbd; /* Track color (gray) */
    border-radius: 5px;
  }
  
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: #c1c1c1; /* Track color (gray) */
    border-radius: 5px;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #002A4C; /* Thumb color (dark blue) */
    border-radius: 50%;
    cursor: pointer;
    margin-top: -5px; /* Adjust to center the thumb vertically */
  }
  
  /* For Firefox */
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 8px;
    background: #cccccc; /* Track color (gray) */
    border-radius: 5px;
  }
  
  input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #002A4C; /* Thumb color (dark blue) */
    border-radius: 50%;
    cursor: pointer;
  }
  
  /* For Internet Explorer */
  input[type="range"]::-ms-track {
    width: 100%;
    height: 8px;
    background: #d3d1d1; /* Track color (gray) */
    border-radius: 5px;
    border: none;
  }
  
  input[type="range"]::-ms-thumb {
    width: 20px;
    height: 20px;
    background: #002A4C; /* Thumb color (dark blue) */
    border-radius: 50%;
    cursor: pointer;
  }
  